import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _unicodeProperties3 from "@react-pdf/unicode-properties";

var _unicodeProperties2 = "default" in _unicodeProperties3 ? _unicodeProperties3.default : _unicodeProperties3;

import _empty2 from "../../attributedString/empty";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _unicodeProperties = _interopRequireDefault(_unicodeProperties2);

var _empty = _interopRequireDefault(_empty2);

var ignoredScripts = ["Common", "Inherited", "Unknown"];
/**
 * Resolves unicode script in runs, grouping equal runs together
 *
 * @param  {Object}  layout options
 * @param  {Object}  attributed string
 * @return {Object} attributed string
 */

var scriptItemizer = function scriptItemizer(options, attributedString) {
  var string = attributedString.string;
  var lastScript = "Unknown";
  var lastIndex = 0;
  var index = 0;
  var res = [];
  if (!string) return (0, _empty.default)();

  for (var i = 0; i < string.length; i += 1) {
    var char = string[i];
    var codePoint = char.codePointAt();

    var script = _unicodeProperties.default.getScript(codePoint);

    if (script !== lastScript && !ignoredScripts.includes(script)) {
      if (lastScript !== "Unknown") {
        res.push({
          start: lastIndex,
          end: index,
          attributes: {
            script: lastScript
          }
        });
      }

      lastIndex = index;
      lastScript = script;
    }

    index += char.length;
  }

  if (lastIndex < string.length) {
    res.push({
      start: lastIndex,
      end: string.length,
      attributes: {
        script: lastScript
      }
    });
  }

  return {
    string: string,
    runs: res
  };
};

var _default = R.curryN(2, scriptItemizer);

exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;